<template>
    <section class="choose-tour-type">
        <header>
            <h2>Recyclehero in {{ city }}!</h2>
        </header>

        <FormKit v-model="formData" type="form" :actions="false">
            <FormKit
                type="radio"
                name="tourType"
                :classes="{ outer: 'big', input: 'light' }"
                :options="formOptions"
                @input="handleInput"
            >
                <template #label="context">
                    <div class="formkit-label">
                        <span>{{ context.option.label }}</span>
                        <img
                            v-if="
                                tourTypes.includes(
                                    Enums.tourTypes['2ndhandgoods'],
                                ) &&
                                context.option.value ===
                                    Enums.tourTypes['2ndhandgoods'] &&
                                isParcelShippingCarrier
                            "
                            src="/images/solutions/shipping_service.jpg"
                            alt=""
                            width="500"
                            height="334"
                        />
                        <img
                            v-else-if="
                                tourTypes.includes(
                                    Enums.tourTypes['2ndhandgoods'],
                                ) &&
                                context.option.value ===
                                    Enums.tourTypes['2ndhandgoods'] &&
                                !context.option.alsoElectronics
                            "
                            src="/images/solutions/clothes.jpg"
                            alt=""
                            width="500"
                            height="334"
                        />
                        <img
                            v-else-if="
                                tourTypes.includes(
                                    Enums.tourTypes['2ndhandgoods'],
                                ) &&
                                context.option.value ===
                                    Enums.tourTypes['2ndhandgoods'] &&
                                context.option.alsoElectronics
                            "
                            src="/images/solutions/clothes_handys.jpg"
                            alt=""
                            width="500"
                            height="334"
                        />
                    </div>
                </template>
            </FormKit>
        </FormKit>
    </section>
</template>

<script>
import useOrderStore from '@/stores/order'

export default {
    emits: ['close', 'result'],

    setup() {
        return {
            filters: useFilters,
        }
    },

    data() {
        const orderStore = useOrderStore()

        return {
            formData: {
                tourType: undefined,
            },
            formDataReminder: {
                email: '',
            },
            errorsReminder: [],
            stateReminder: 'edit',
            tourTypes: orderStore.productTypes,
            zipcodeIsInAtLeastOneBasearea:
                orderStore.zipcodeIsInAtLeastOneBasearea,
            baseareaName: orderStore.baseareaName,
            baseareaCode: orderStore.baseareaCode,
            supportedProducts: orderStore.supportedProducts,
            city: orderStore.settings.city,
            isParcelShippingCarrier: orderStore.isParcelShippingCarrier,
        }
    },

    computed: {
        formOptions() {
            const options = []
            if (this.tourTypes.includes(Enums.tourTypes['2ndhandgoods'])) {
                const alsoElectronics = this.supportedProducts.some(
                    (product) => product.name === 'Elektrogeräte',
                )
                options.push({
                    value: Enums.tourTypes['2ndhandgoods'],
                    label: alsoElectronics ? 'Altkleider & Elektrogeräte' : 'Altkleider',
                    attrs: {},
                    alsoElectronics: alsoElectronics,
                })
            }

            if (options.length >= 1) {
                options[0].attrs.selected = true
            }

            return options
        },
    },

    methods: {
        handleInput(type) {
            this.$emit('result', type)

            setTimeout(() => {
                this.$emit('close')
            }, 250)
        },
    },
}
</script>

<style lang="scss" scoped>
// @use 'sass:math';
@import '~/assets/config';

.choose-tour-type {
    position: relative;
    width: 90vw;
    max-width: 100%;
}

header {
    > h2 {
        margin-bottom: 1.5rem;
    }
}

:deep(.formkit-outer) {
    .formkit-fieldset {
        max-width: none;
        margin: 0;
        padding: 0;
    }

    .formkit-options {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: space-between;
        gap: 0.5rem;
    }

    .formkit-option {
        flex: auto 1 1;
        width: 100%;
        padding: 0.75rem;
        @include shadow-light;
        transition: box-shadow 0.35s;

        &[data-checked='true'] {
            box-shadow: 0px 0px 4px theme('colors.primary-light' / 1);
        }
    }

    .formkit-wrapper {
        position: relative;
        max-width: none;
    }

    .formkit-inner {
        position: absolute;
        top: 0.125rem;
        left: 0;
    }

    .formkit-label {
        width: 100%;

        span {
            @apply text-xl;
            @apply font-display;
            @apply font-semibold;
            display: block;
            padding-left: 2.25rem;
            margin-bottom: 0.5rem;
        }

        img {
            display: block;
            width: 100%;
            max-width: none;
            max-height: 8rem;
            object-fit: cover;
        }
    }
}

@screen sm {
    .choose-tour-type {
        width: 80vw;
    }

    header {
        > h2 {
            margin-bottom: 2rem;
        }
    }

    :deep(.formkit-outer) {
        .formkit-fieldset {
            max-width: none;
            margin: 0;
            padding: 0;
        }

        .formkit-options {
            flex-flow: row nowrap;
            align-items: stretch;
            gap: 2rem;
        }

        .formkit-option {
            width: 50%;
            padding: 1rem;
            margin: 0;
            flex: auto 1 1;
        }

        .formkit-wrapper {
            display: flex;
            flex-flow: column nowrap;
            height: 100%;
        }

        .formkit-label {
            flex: auto 1 0;
            display: flex;
            flex-flow: column nowrap;

            span {
                margin-bottom: 1rem;
            }

            img {
                margin-top: auto;
                max-height: none;
            }
        }
    }
}

@screen md {
    .choose-tour-type {
        width: 70vw;
    }

    :deep(.formkit-outer) {
        .formkit-label {
            span {
                @apply text-2xl;
            }
        }
    }
}

@screen lg {
    .choose-tour-type {
        width: 40vw;
    }
}
</style>
